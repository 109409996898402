@import "styles/variables";
@import "styles/mixings";

.box {

  div:first-child {
    background-color: #E9F5FF!important;
    height: 121px;
    background: url("../../../../public/assets/resort/blue-mountains.svg") 50% 105% repeat-x;
  }
}