@import "styles/variables";
@import "styles/mixings";

.content {
  width: 100%;
}

.photo {
  min-height: 760px;
  background-size: cover;
  background-position: 50% 50%;
  position: relative;
  display: flex;
  align-items: center;

  > :global(.container) {
    z-index: 3;
    position: relative;
  }

  @include media-breakpoint-down(md) {
    min-height: 400px;
    padding: 0 10px;
  }

  .background {
    background: #006fac;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    opacity: 0.6;
  }

  .container {
    margin-bottom: 50px;

    .title {
      font-weight: bold;
      font-size: 56 * $px-base;
      line-height: 84 * $px-base;
      color: $white;
      margin-bottom: 13px;

      @include media-breakpoint-down(md) {
        font-size: 26 * $px-base;
        line-height: 39 * $px-base;
        align-items: center;
        text-align: center;
        margin-bottom: 27px;
      }
    }

    .description {
      max-width: 885px;
      font-weight: normal;
      font-size: 20 * $px-base;
      line-height: 140%;
      color: $white;
      opacity: 0.9;
      margin: 0 0 80px;

      @include media-breakpoint-down(md) {
        font-size: 14 * $px-base;
        line-height: 140%;
        align-items: center;
        text-align: center;
        margin-bottom: 46px;
      }
    }

    .fontBlack {
      color: $black;
    }

    .search {
      margin: 0;
    }
  }

  .lightBackground {
    background: transparent;
  }
}
