@import "styles/variables";
@import "styles/mixings";

.wrapper {
    @include media-breakpoint-up(lg) {
        position: relative;
        height: 780px;
        padding-top: 20px;
    }
}

.widget {
    @include media-breakpoint-up(lg) {
        width: 516px;
        position: absolute;
        padding: 20px;
        background-color: #fff;
        margin: 30px 0 0 30px;
        border-radius: 10px;
        box-shadow: 0 0px 20px rgba(0, 0, 0, 0.2);
    }
}

.overlay {
    position: absolute;
    margin-left: 260px;
    width: calc(100% - 300px);
    height: 600px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 14px;

    @include media-breakpoint-down(lg) {
        display: none;
    }

}

.copyWrapper {
    padding: 1em;
}
