@import "styles/variables";
@import "styles/mixings";

.sky {
  margin-top: 20px;
  margin-bottom: 20px;
}

.infinite {
  margin-top: 20px;
  margin-bottom: 20px;

  min-height: 90px;

  @include media-breakpoint-down(lg) {
    min-height: 250px;
  }
}

.popup {
  width: 1px;
  height: 1px;
  margin: 0;
  position: fixed;
}

.debug {
  background: #8ccbde;
}

.main {
  display: block;
  overflow: hidden;
  text-align: center;

  > div {
    margin: 0 auto;
  }
}

:global(.search-overlay) .main {
  position: relative !important;
  z-index: 0 !important;
}

.sticky {
  position: sticky;
  top: 20px;
}

.adMonitorWrapper {
  &.inactive {
    margin: 0 !important;
    padding: 0 !important;
    height: 0;
  }
}

.adMonitor {
  position: absolute;
  border-radius: 10px;
  width: 10px;
  height: 10px;
  z-index: 100;
  transform: translateY(-5px);
  // pointer-events: none;

  &.production {
    opacity: 0;
    pointer-events: none;
  }

  &.active {
    background: #0B7595;
    z-index: 101;
    transform: translateY(-5px) translateX(2px);
  }

  &.inactive {
    background: red;
    transform: translateY(-5px) translateX(-2px);
  }
}
