@import "styles/variables";
@import "styles/mixings";

.popularSearches {
  width: 60%;
  margin: 0 auto;

  @include media-breakpoint-down(xl) {
    width: 100%;
  }

  .title {
    text-align: center;
    letter-spacing: 0.065em;
    text-transform: uppercase;
    color: $white;
    overflow: hidden;
    margin-bottom: 20px;
    font-size: 2rem;

    @include media-breakpoint-down(md) {
      display: none;
    }

    span {
      display: inline-block;
      vertical-align: middle;
    }

    &::before, &::after {
      content: "";
      display: inline-block;
      vertical-align: middle;
      width: 100%;
      height: 1px;
      background: $white;
      opacity: 0.4;
      position: relative;
    }

    &::before {
      margin-left: -100%;
      left: -17px;
    }
    &::after {
      margin-right: -100%;
      right: -17px;
    }
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    border-radius: 5px;
    overflow: hidden;

    @include media-breakpoint-down(md) {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;
    }

    .cell {
      min-height: 50px;
      background: $white;
      opacity: 0.85;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border: none;
      appearance: none;

      @include media-breakpoint-down(md) {
        flex-basis: 49%;
        min-height: 45px;
        margin-top: 1px;
        border-radius: 0 !important;

        &:nth-child(2n+1) {
          border-radius: 0;
          margin: 1px 1px 0 0 !important;
        }

        &:nth-child(2n) {
          margin: 1px 0 0 !important;
        }
      }

      &:nth-child(2) {
        margin: 0 1px;
      }

      &:nth-child(4) {
        margin-top: 1px;
      }

      &:nth-child(5) {
        margin: 1px 1px 0;
      }

      &:nth-child(6) {
        margin-top: 1px;
      }

      &:hover {
        opacity: 1;

        .query {
          color: #006FAC;
          font-weight: 600;
        }
      }

      .query {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 50px;
        font-weight: normal;
        font-size: 16 * $px-base;
        line-height: 24 * $px-base;
        color: $secondary;
        opacity: 0.9;
        height: 50px;
      }
    }
  }
}
