@import "styles/variables";
@import "styles/mixings";

$margin: 18px;

.bestResorts {
  h2 {
    font-weight: bold;
    font-size: 32 * $px-base;
    line-height: 48 * $px-base;
    text-align: center;
    color: $black;
    margin-bottom: 16px;

    @include media-breakpoint-down(md) {
      font-size: 20 * $px-base;
      line-height: 30 * $px-base;
      background: linear-gradient(180deg, rgba(12, 113, 176, 0.1) 0%, rgba(255, 255, 255, 0.1) 100%);
      padding: 20px 0;
      margin-bottom: 0;
    }
  }

  header {
    background: $white;
    box-shadow: 0 1px 36px rgba(2, 56, 85, 0.05);
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 14px 30px;
    margin-bottom: 20px;

    @include media-breakpoint-down(md) {
      flex-direction: column;
      padding: 0 10px;
      margin-bottom: 0;
    }

    .image {
      height: auto;
      text-align: center;

      img {
        width: 100%;
        margin-right: 26px;
        max-width: 63px;
      }

      @include media-breakpoint-down(md) {
        width: 100%;

        img {
          margin-right: 0;
        }

        &::before,
        &::after {
          content: "";
          display: inline-block;
          vertical-align: middle;
          width: 20%;
          height: 1px;
          background: #c4c4c4;
          margin: 0 17px;
        }
      }
    }

    .title {
      font-weight: bold;
      font-size: 22 * $px-base;
      line-height: 33 * $px-base;
      letter-spacing: 0.04em;
      color: $primary;

      @include media-breakpoint-down(md) {
        font-size: 20 * $px-base;
        line-height: 30 * $px-base;
        text-align: center;
        letter-spacing: 0.04em;
      }
    }
  }

  .controlTabs {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 6px;

    &.isFull {
      .tab {
        @media screen and (max-width: 1400px) {
          width: calc(33% - 24px);
        }

        @include media-breakpoint-down(xl) {
          width: calc(50% - 24px);
        }

        @include media-breakpoint-down(lg) {
          width: calc(33% - 24px);
        }

        @include media-breakpoint-down(md) {
          width: auto;
        }
      }
    }

    @include media-breakpoint-down(md) {
      padding: 8px 10px 0;
      justify-content: center;
      background: #f9f9f9;
      margin-bottom: 0;
    }

    .tab {
      font-weight: 500;
      font-size: 16 * $px-base;
      line-height: 24 * $px-base;
      color: #11abd8;
      background: $white;
      box-shadow: 0 1px 36px rgba(2, 56, 85, 0.05);
      border-radius: 8px;
      border: none;
      height: 50px;
      width: calc(25% - 24px);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin: 0 12px 19px;

      @include media-breakpoint-down(lg) {
        width: calc(33% - 24px);
      }

      @include media-breakpoint-down(md) {
        min-width: auto;
        width: auto;
        height: 35px;
        font-weight: 600;
        font-size: 14 * $px-base;
        line-height: 21 * $px-base;
        margin: 0 10px 10px 0;
        padding: 0 17px;
      }

      &:hover {
        background: $primary;
        font-weight: bold;
        color: $white;
      }

      &.active {
        background: $primary;
        font-weight: bold;
        color: $white;
      }
    }
  }

  .descriptionBlock {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;
    padding: 0 30px;

    @include media-breakpoint-down(md) {
      background: #f9f9f9;
      flex-direction: row-reverse;
      align-items: flex-start;
      padding: 12px 10px 22px;
      margin-bottom: 0;
    }

    .text {
      flex-basis: 85%;
      margin-right: 30px;

      @include media-breakpoint-down(md) {
        margin-right: 0;
      }

      .title {
        font-size: 20 * $px-base;
        line-height: 30 * $px-base;
        color: $black;
        margin-bottom: 10px;
        font-weight: 700;
        display: block;
      }

      p {
        font-size: 16 * $px-base;
        line-height: 24 * $px-base;
        color: $black;
        margin: 0;

        @include media-breakpoint-down(md) {
          font-size: 14 * $px-base;
          line-height: 21 * $px-base;
        }
      }
    }

    .img {
      position: relative;
      top: 15px;

      @include media-breakpoint-down(md) {
        margin-right: 28px;
        position: static;
      }

      svg {
        width: 80px;
        height: 80px;
      }
    }
  }

  .resortList {
    &.w50 {
      >div {
        >div {
          &:not(.wrapperAdBlock) {
            width: calc(50% - 36px);

            @include media-breakpoint-down(md) {
              width: 100%;
            }
          }

          &.wrapperAdBlock {
            width: 100%;
          }
        }
      }
    }

    >div {
      display: flex;
      margin: -$margin;
      flex-wrap: wrap;

      >div {
        width: calc(33% - 36px);
        flex-grow: 0;
        margin: $margin $margin;
      }

      &.isLoading {
        justify-content: center;
      }

      @include media-breakpoint-down(xxl) {
        >div {
          width: calc(50% - 36px);
        }
      }

      @include media-breakpoint-down(xl) {
        margin: 0;
      }

      @include media-breakpoint-down(md) {
        margin: -$margin 0;

        >div {
          width: 100%;
          margin: $margin $margin * 0.5;
          padding-top: 14px;
        }
      }
    }

    .unit {
      width: 100%;
    }

    .banner {
      display: none;
      max-width: 100%;
      height: auto;
      padding: 0 10px;
      margin: 20px 0 10px 0;

      @include media-breakpoint-down(md) {
        display: block;
      }
    }

    .resortCard {
      min-width: 260px;
      flex-shrink: 1;
      border-radius: 8px;

      @include media-breakpoint-down(md) {
        margin-bottom: 0;
      }

      figure {
        width: 100%;
        height: 170px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        margin-bottom: 0;
        background-size: cover;
        background-color: $primary-blue;
      }

      .title {
        font-weight: 600;
        font-size: 20 * $px-base;
        line-height: 30 * $px-base;
        color: #11abd8;
        margin-bottom: 15px;

        .index {
          padding: 3px 8px;
          background: $primary;
          border-radius: 8px;
          color: $white;
          margin-right: 10px;

          &:before {
            content: "#";
          }
        }
      }

      .image {
        height: 242px;
        background-size: cover;
        border-radius: 8px 8px 0 0;
      }

      footer {
        width: 100%;
        background: $white;
        box-shadow: 0 0 45px -7px rgba(2, 56, 85, 0.12);
        border-radius: 0 0 8px 8px;
        padding: 25px 21px;

        >a {
          display: flex;
          justify-content: space-between;
          align-items: center;

          @include media-breakpoint-down(xl) {
            flex-direction: column-reverse;
            align-items: flex-start;
          }

          @include media-breakpoint-down(md) {
            flex-direction: row;
            align-items: center;
          }

          @include media-breakpoint-down(sm) {
            flex-direction: column-reverse;
            align-items: flex-start;
          }
        }

        .stars {
          white-space: nowrap;
          display: flex;
          flex-wrap: nowrap;
          align-items: center;

          svg {
            width: 25px;
            height: 25px;
            margin-right: 8px;
          }

          .number {
            margin-left: 0;
            color: $text;
            font-size: 18 * $px-base;
            line-height: 20 * $px-base;
            padding-top: 2 * $px-base;
          }
        }

        .reviewsCount {
          font-weight: 600;
          font-size: 16 * $px-base;
          line-height: 24 * $px-base;
          text-align: right;
          color: #11abd8;
        }
      }
    }
  }

  .loadMore {
    font-weight: bold;
    font-size: 15 * $px-base;
    line-height: 20 * $px-base;
    text-transform: uppercase;
    color: $white;
    background: $primary;
    border: none;
    border-radius: 5px;
    padding: 15px 24px;
    display: block;
    margin: 40px auto 0;

    @include media-breakpoint-down(md) {
      margin-top: 60px;
    }
  }

  .boxLoader {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
}