@import "styles/variables";
@import "styles/mixings";

.box {
  max-height: 35vw;
  border-radius: 8px;
  position: relative;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  width: auto;
  background: $secondary-alt 50% 50% no-repeat;
  background-size: cover;
  overflow: hidden;
  height: 494px;

  &.iframe {
    height: auto;
    padding-bottom: 66%;
    max-height: none;
    background: $secondary-light-blue 50% 50% no-repeat;

    @include media-breakpoint-down(xxl) {
      padding-bottom: 73%;
    }

    @include media-breakpoint-down(lg) {
      padding-bottom: 74%;
    }

    @include media-breakpoint-down(md) {
      padding-bottom: 58%;
    }

    &.feratel {
      @include media-breakpoint-down(md) {
        padding-bottom: 177.78%; // 9:16 proportion for mobile on v5 Feratel
      }
    }
  }

  &.noTop {
    // border-top-left-radius: 0;
    // border-top-right-radius: 0;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }


  & figcaption {
    background: #fff;
    text-align: center;
    padding: 5px 0;
  }

  @include media-breakpoint-down(md) {
    height: 286px;
    max-height: none;

    .allCams {
      display: block;

      a {
        border-radius: 5px;
        height: 36px;
        line-height: 36px;
        position: absolute;
        background: $primary-alt;
        color: $white;
        top: 29px;
        right: 34px;
        padding: 0 12px;

        svg {
          margin-left: 5px;
          height: 6.7px;
          fill: $white;
        }
      }
    }
  }

}

.sideView {
  height: 230px;
}

.skireportView {
  height: 300px;
  margin-bottom: 0;
}

.mainView {
  figcaption {
    padding: 14px 27px;
    text-align: start;
    display: flex;
    justify-content: space-between;

    &>* {
      display: flex;
      color: $white;

      &>span {
        &:first-child {
          font-size: 24 * $px-base;
        }

        &:nth-child(2) {
          color: rgba(255, 255, 255, 0.8);
        }
      }
    }
  }
}