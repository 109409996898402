@use "sass:math";

@import "styles/variables";
@import "styles/mixings";

.box {
  padding: 6px 10px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;

  .title {
    margin-bottom: 0;
    font-size: 1.1rem;
    font-weight: 400;
    color: #082450;
  }

  &.noTitle {
    justify-content: flex-end;
  }

  .links {
    margin-top: auto;
    text-align: right;
    font-size: 12px;
  }

  @include media-breakpoint-down(lg) {
    padding-top: 21px;
    padding-bottom: 12px;
    border-bottom: none;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    a {
      font-size: 14 * $px-base;
    }
  }
}