@import "styles/variables";
@import "styles/mixings";

$margin: 18px;

.box {
  display: flex;
  flex-wrap: wrap;


  @include media-breakpoint-up(xl) {
    margin: -$margin;
  }

  >div {
    width: calc(33% - 36px);
    flex-grow: 0;
    margin: $margin $margin;
  }

  @include media-breakpoint-down(xxl) {
    >div {
      width: calc(50% - 36px);
    }
  }

  @include media-breakpoint-down(md) {

    >div {
      width: 100%;
      margin: $margin $margin*0.5;
    }
  }

  @include media-breakpoint-down(lg) {
    h3 {
      text-align: center;
    }
  }
}