.wrapper {
  height: 0;
  position: relative;

  // TODO: transition: height 0.5s ease 0.5s;
  transition: height 0.01s ease 0.2s; // This essentially disables animation

  &.active {
    .inner {
      pointer-events: auto;
      opacity: 1;
      clip-path: inset(0 0 0);
    }
  }

  .inner {
    position: absolute;
    left: 0;
    right: 0;
    pointer-events: none;
    opacity: 0.1;
    //TODO: transition: opacity 0.5s ease 0.5s, clip-path 0.5s ease 0.5s;
    transition: opacity 0.01s linear 0.2s, clip-path 0.01s linear 0.2s; // This essentially disables animation
    clip-path: inset(0 0 100%);
  }
}