@import "styles/variables";
@import "styles/mixings";

.box {
  width: 30%;
  min-width: 260px;
  flex-grow: 1;
  flex-shrink: 1;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 45px -7px rgba(2, 56, 85, 0.12);

  &.isCurrent {
    box-shadow: 0 0 0 3px rgb(0 111 172 / 40%), 0 0 45px -7px rgb(2 56 85 / 12%);
  }

  figure {
    height: 200px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    background: #576A85 url('./noimage.svg') 50% 50% no-repeat;
    margin: 0;

    figcaption {
      color: white;
      background: #0E7DB9;
      line-height: 25px;
      padding: 0 8px;
      margin-bottom: 11px;
      text-align: center;
      border-radius: 3px;
    }
  }
  .linkResort {
    padding: 0;
  }
  a, div {
    display: block;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    .title {
      font-size: 19 * $px-base;
      font-weight: normal;
      color: $black;
    }
    .subtitle {
      font-size: $px-base * 14;
      color: $secondary-blue;
      font-weight: normal;
    }
  }
}

.hideOnMobile {
  @include media-breakpoint-down(sm) {
    display: none!important;
  }
}

.showOnMobile {
  display: none!important;

  @include media-breakpoint-down(sm) {
    display: block!important;
  }
}
