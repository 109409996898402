@import "styles/mixings";

.layout {
  display: flex;

  .main {
    flex: 1;
    min-width: 0;

    >* {
      margin-bottom: 24px;
    }
  }

  .side {
    width: 300px;
    margin-left: 30px;
    flex-shrink: 0;

    .sticky {
      position: sticky;
      top: 20px;

      >div {
        margin-bottom: 40px;
      }
    }

    >article:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  @include media-breakpoint-down(xxl) {
    .side {
      min-width: 300px;
      margin-left: 30px;
    }
  }

  @include media-breakpoint-down(xl) {
    .main {
      >div {
        margin-bottom: 30px;
      }
    }

    .side {
      min-width: 160px;
      margin-left: 20px;
      flex-shrink: 1;

      .sticky {
        >* {
          margin-bottom: 30px;
        }
      }
    }
  }

  @include media-breakpoint-down(lg) {
    display: block;

    .main {
      >* {
        margin-bottom: 20px;
      }

      margin-bottom: 20px;
    }

    .side {
      width: auto;
      min-width: 0;
      margin-left: 0;
      flex-shrink: 1;

      .sticky {
        position: static;
        top: auto;

        >* {
          margin-bottom: 20px;
        }
      }
    }
  }
}