@import "styles/variables";
@import "styles/mixings";

$margin: 18px;

.snowWebcams {
  .title {
    font-weight: bold;
    font-size: 32 * $px-base;
    line-height: 48 * $px-base;
    text-align: center;
    color: $black;
    margin-bottom: 35px;

    @include media-breakpoint-down(md) {
      font-size: 20 * $px-base;
      line-height: 30 * $px-base;
      margin-bottom: 21px;
    }
  }

  .wrapperBox {
    >div {
      display: flex;
      margin: -$margin;
      flex-wrap: wrap;

      >div {
        width: calc(33% - 36px);
        flex-grow: 0;
        margin: $margin $margin;
      }

      @include media-breakpoint-down(xxl) {
        >div {
          width: calc(50% - 36px);
        }
      }

      @include media-breakpoint-down(md) {
        margin: -$margin 0;

        >div {
          width: 100%;
          margin: $margin $margin*0.5;
        }
      }
    }
  }
}