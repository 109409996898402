@import "styles/variables";
@import "styles/mixings";

.box {
  background: #FFFFFF;
  border-radius: 8px;

  @include media-breakpoint-down(lg) {
    box-shadow: none;
    border-radius: 0;
    // background: linear-gradient(180deg, rgba(12, 113, 176, 0.05) 0%, rgba(255, 255, 255, 0.1) 90px);
  }
}