@import "styles/variables";
@import "styles/mixings";

.title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: black;
  padding: 0.5vh 1vw;

  &:hover {
    color: inherit;
  }

  &>span {
    &:first-child {
      font-weight: 600;
      font-size: 19 * $px-base;
      line-height: 24px;
    }

    &:nth-child(2) {
      font-size: 16 * $px-base;
      display: none;
    }
  }

  @include media-breakpoint-down(xl) {
    padding: 0.5vh 2vw;
  }

  @include media-breakpoint-down(lg) {
    padding: 0.5vh 0;

    &>span {
      &:first-child {
        font-size: 18 * $px-base;
      }

      &:nth-child(2) {
        color: rgba(255, 255, 255, 0.8);
        display: block;
      }
    }
  }
}