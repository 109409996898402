@import "styles/variables";
@import "styles/mixings";

.photo {
  min-height: 570px;
  background-color: $primary;
  background-size: cover;
  background-position: 50% 50%;
  position: relative;
  text-align: center;

  >:global(.container) {
    z-index: 11;
    height: 570px;
    position: relative;
  }

  @include media-breakpoint-down(md) {
    min-height: 500px;
    >:global(.container) {
      height: 500px;
    }
  }

  .container {
    padding-top: 60px;

    @include media-breakpoint-down(lg) {
      padding: 80px 10px 0;
    }
  }

  .title {
    font-size: 45 * $px-base;
    line-height: 70 * $px-base;
    color: $white;
    font-weight: bold;
    margin: 0;

    @include media-breakpoint-down(md) {
      font-size: 32 * $px-base;
      line-height: 48 * $px-base;
      margin-bottom: 12px;
    }
  }

  .subtitle {
    font-size: 20 * $px-base;
    line-height: 140%;
    font-weight: normal;
    color: $white;
    margin-bottom: 25px;

    @include media-breakpoint-down(md) {
      font-size: 14 * $px-base;
      margin-bottom: 20px;
    }
  }

  .background {
    background: #006FAC;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    opacity: 0.1;
  }

  .mountains {
    z-index: 2;
    position: absolute;
    bottom: 0;
    top: 0;
    width: 100%;
    background: url(../../../../public/assets/resort/white-mountains.svg) 50% 101% repeat-x;

    @include media-breakpoint-down(lg) {
      display: none;
    }
  }
}
