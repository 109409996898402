@import "styles/variables";
@import "styles/mixings";

$margin: 18px;

.wrapper {
  h2 {
    font-weight: bold;
    font-size: 32 * $px-base;
    line-height: 48 * $px-base;
    text-align: center;
    color: $black;
    margin-bottom: 16px;

    @include media-breakpoint-down(md) {
      font-size: 20 * $px-base;
      line-height: 30 * $px-base;
      background: linear-gradient(180deg, rgba(12, 113, 176, 0.1) 0%, rgba(255, 255, 255, 0.1) 100%);
      padding: 20px 0;
      margin-bottom: 0;
    }
  }

  .newsList {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .isLoading {
    display: flex;
    justify-content: center;
  }

  .wrapperBox {
    >div {
      display: flex;
      margin: 0 -$margin 0 -$margin;
      flex-wrap: wrap;

      .w100 {
        width: 100%;
      }

      >div {
        width: calc(33% - 36px);
        flex-grow: 0;
        margin: $margin $margin;
      }

      @include media-breakpoint-down(xl) {
        margin: 0;
      }

      @include media-breakpoint-down(lg) {

        >div {
          width: 100%;
          margin: $margin $margin*0.5;
        }
      }
    }


    .box {
      min-width: 260px;
      flex-shrink: 1;
      border-radius: 8px;

      figure {
        width: 100%;
        height: 170px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        margin-bottom: 0;
        border-radius: 8px 8px 0 0;
        background-color: $primary-blue;
      }

      div.footer {
        background: $white;
        box-shadow: 0 0 45px -7px rgba(2, 56, 85, 0.12);
        padding: 25px 21px;
        border-radius: 0 0 8px 8px;

        .title {
          font-weight: 600;
          font-size: 22 * $px-base;
          line-height: 33 * $px-base;
          color: $black;
          margin-bottom: 12px;

          @include media-breakpoint-down(md) {
            font-size: 20 * $px-base;
            line-height: 140%;
          }
        }

        p {
          font-size: 16 * $px-base;
          line-height: 24 * $px-base;
          color: $black;
          margin: 0;

          max-height: 3 * 24 * $px-base;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;

          @include media-breakpoint-down(md) {
            font-size: 14 * $px-base;
            line-height: 21 * $px-base;
          }
        }
      }
    }
  }

  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 36px;

    .page {
      font-weight: bold;
      font-size: 16 * $px-base;
      line-height: 20 * $px-base;
      color: $primary-alt;
      background: $white;
      border-radius: 5px;
      padding: 15px 20px;
      margin-right: 10px;
      border: none;

      @include media-breakpoint-down(md) {
        font-size: 14 * $px-base;
        line-height: 21 * $px-base;
        padding: 10px 15px;
        margin-right: 5px;
      }

      &:last-child {
        margin-right: 0;
      }

      &.active {
        background: $primary;
        color: $white;
      }
    }
  }

  .btn {
    display: flex;
    justify-content: center;

    a {
      padding: 15px 16px;
      font-weight: bold;
      font-size: 16 * $px-base;
      line-height: 20 * $px-base;
      color: #FFFFFF;
      background: #006FAC;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 35px;
    }
  }
}